import * as React from "react"
//import { useStaticQuery, graphql } from "gatsby"
//import Header from "./header"
import "./layout.css"
import { MenuIcon } from '@heroicons/react/solid'
import { useState } from "react"
import classNames from 'classnames'
import { AnchorLink } from "gatsby-plugin-anchor-links";

interface LayoutProps {
  children: React.ReactNode[] | React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <header className="min-h-screen relative">

        {/* <div className="container mx-auto px-4">
          <div className="flex items-center justify-between py-4 text-white hover:text-gray-50">

            <a href="/">Tatra Software</a>

            <button className={classNames(["transform transition-transform duration-700 ease-in-out  inline-block md:hidden w-8 h-8 p-1", { "rotate-180": isMenuOpen }])}>
              <MenuIcon onClick={() => setIsMenuOpen(!isMenuOpen)} />
            </button>

            <nav className={classNames(["absolute md:relative top-16 left-0 md:top-0 md:flex flex-col md:flex-row md:space-x-6 font-semibold w-full md:w-auto", isMenuOpen ? 'flex' : "hidden"])}>
              <AnchorLink to="/#home" title="Home" className="block rounded-lg md:rounded-none p-2 md:p-0 mt-1 md:m-0 bg-white bg-opacity-30 md:bg-transparent text-gray-600 hover:underline" />
              <AnchorLink to="/#about-us" title="About Us" className="block rounded-lg md:rounded-none p-2 md:p-0 mt-1 md:m-0 bg-white bg-opacity-30 md:bg-transparent text-gray-600 hover:underline" />
              <AnchorLink to="/#services" title="Services" className="block rounded-lg md:rounded-none p-2 md:p-0 mt-1 md:m-0 bg-white bg-opacity-30 md:bg-transparent text-gray-600 hover:underline" />
              <AnchorLink to="/#skills" title="Skills" className="block rounded-lg md:rounded-none p-2 md:p-0 mt-1 md:m-0 bg-white bg-opacity-30 md:bg-transparent text-gray-600 hover:underline" />
              <AnchorLink to="/#contact" title="Contact" className="block rounded-lg md:rounded-none p-2 md:p-0 mt-1 md:m-0 bg-white bg-opacity-30 md:bg-transparent text-gray-600 hover:underline" />
            </nav>

          </div>
        </div> */}
        <div className="flex h-screen">
          <div className="m-auto text-center text-white text-opacity-90">
            <h1 className="text-6xl drop-shadow-lg" style={{ textShadow: "2px 2px 10px rgba(0,0,0,0.30)" }}>Tatra Software</h1>

            <p className="text-2xl" style={{ textShadow: "2px 2px 10px rgba(0,0,0,0.30)" }}>Coming Soon</p>
          </div>
        </div>


      </header >

      {/* <div className="absolute top-0 left-0 min-h-screen w-full bg-blue-600 bg-opacity-10">
        <div className="absolute text-right bottom-0 w-full text-white text-xs">
          <p className="p-1">
            Photo by <a href="https://unsplash.com/photos/_F9rJR86qf4">Michał Parzuchowski</a>
          </p>
        </div>
      </div> */}

      {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}

      <div className="w-full">
        <main>
          {children}
        </main>

        {/* <footer className="container mx-auto mt-6 p-16 border-t border-gray-200">

          <div className="flex items-center justify-center space-x-4">
            <a href="https://www.gatsbyjs.com/" target="_blank">
              <img src="/images/logos/gatsby.svg" alt="Gatsby" className="flex-initial h-8" />
            </a>
            <a href="https://www.netlify.com/" target="_blank">
              <img src="/images/logos/netlify.svg" alt="Netlify" className="flex-initial h-8" />
            </a>
            <a href="https://reactjs.org/" target="_blank">
              <img src="/images/logos/react.svg" alt="React" className="flex-initial h-8" />
            </a>
            <a href="https://www.typescriptlang.org/" target="_blank">
              <img src="/images/logos/typescript.svg" alt="TypeScript" className="flex-initial h-8" />
            </a>
            <a href="https://tailwindcss.com/" target="_blank">
              <img src="/images/logos/tailwindcss.svg" alt="Tailwind CSS" className="flex-initial h-8" />
            </a>
          </div>

          <p className="text-center pt-4">© {new Date().getFullYear()} Tatra Software</p>
        </footer> */}
      </div>
    </>
  )
}

export default Layout